import * as React from "react";
import {
  BG,
  Rule,
  RuleDescription,
  RuleRow,
  Rules,
  RulesBox,
  RuleTitle,
  Title,
} from "../styles/TOS";

export const TOS = () => (
  <BG>
    <RulesBox>
      <Title>Tess Rules</Title>
      <Rules>
        <RuleRow>
          <Rule>
            <RuleTitle>Rule #1 - No exploitation</RuleTitle>
            <RuleDescription>
              Do not exploit or distribute bugs, ideas or otherwise dangerous
              knowledge of the company to other users/people/bots. If you are
              caught distributing or exploiting bugs in the programs, you will
              be banned. This means to attack or abuse certain programming
              'glitches' or 'bugs.' This is a bannable offence.
            </RuleDescription>
          </Rule>
          <Rule>
            <RuleTitle>
              Rule #2 - User-bots, Spamming, Automation, and Macros
            </RuleTitle>
            <RuleDescription>
              The use of user bots, macros, scripts, autotypers or anything
              which enables the automation of 'grinding' is against our rules.
              Additionally, large amounts of command spamming is not allowed.
            </RuleDescription>
          </Rule>
          <Rule>
            <RuleTitle>
              Rule #3 - Racism, Homophobia, Transphobia, Sexism, Slurs
            </RuleTitle>
            <RuleDescription>
              We will not tolerate hate speech of any kind through the usage of
              Dank Memer. We will not punish you for what you say outside of the
              use of our commands. Evidence found of this done through our
              services will result in punishment.
            </RuleDescription>
          </Rule>
        </RuleRow>
        <RuleRow>
          <Rule>
            <RuleTitle>Rule #4 - Advertisement</RuleTitle>
            <RuleDescription>
              Using Dank Memer to advertise or promote anything will result in a
              punishment. This rule includes advertising other Discord servers
              or discord bots. You may use our currency in our /serverevents
              system to reward your members for being members, but attaching
              advertisements to the giveaways is not allowed. Patrons who can
              send giveaway messages in our /giveaway system may not advertise
              through that either.
            </RuleDescription>
          </Rule>
          <Rule>
            <RuleTitle>Rule #5 - Real Money Trading</RuleTitle>
            <RuleDescription>
              Tess's currency is not to be traded for real money, discord nitro,
              or other bot currencies. Buying anything with real money outside
              of our Patreon and website will get you a ban.
            </RuleDescription>
          </Rule>
          <Rule>
            <RuleTitle>Rule #6 - Ettiqute</RuleTitle>
            <RuleDescription>
              Starting or spreading harmful rumors about the bot, causing
              unnecessary drama within our servers about the bot, or
              witch-hunting staff members are all ban-worthy behaviors.
            </RuleDescription>
          </Rule>
        </RuleRow>
        <RuleRow>
          <Rule>
            <RuleTitle>Rule #7 - Alternate Accounts</RuleTitle>
            <RuleDescription>
              Alternate account usage is allowed in all circumstances except to
              circumvent a bot ban or vote on bot list websites. The alternate
              accounts must follow the same rules as any actual user, and if you
              break a rule on an alt, we will ban all associated accounts we
              deem as likely alts/mains. You may not use alternate accounts to
              inflate your server member count for /serverevents.
            </RuleDescription>
          </Rule>
        </RuleRow>
      </Rules>
    </RulesBox>
  </BG>
);
