import styled from "styled-components";

export const BG = styled.div`
  background-color: #1c2833;
  width: 100%;
  height: 100vh;
`;

export const RulesBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1`
  font-family: "Inter", sans-serif;
  color: white;
  width: 50%;
  margin: 0 auto;
  padding: 1em 0 0 5em;
`;

export const Rules = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3em;
`;

export const RuleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  @media (max-width: 866px;) {
    flex-direction: column;
  }
`;

export const Rule = styled.div`
  background-color: #37474f;
  border-radius: 10px;
  color: white;
  width: 100%;
  height: 100%;
  padding: 1em;
  margin: 0 1em;
  font-family: "Inter", sans-serif;
  margin: 1em;
`;

export const RuleTitle = styled.h1`
  font-family: "Inter", sans-serif;
`;

export const RuleDescription = styled.p`
  color: white;
`;
